import i18next from "i18next";

function showAlertDialog(data: {
    type: 'danger'|'success',
    title,
    message,
})
{
    let s =
`
<div id="modalId" role="dialog" aria-labelledby="modalLabel" aria-hidden="true" class="modal fade text-left" data-keyboard="false">
<div role="document" class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
        <div class="modal-header">
            <h4 id="modalLabel" class="modal-title">`+data.title+`</h4>
        </div>
        <div class="modal-body">
            <form class="form-horizontal">
                <div class="alert alert-`+data.type+`">
                    `+data.message+`
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn" data-dismiss="modal">`+i18next.t('ok')+`</button>
        </div>
    </div>
</div>
</div>
`;
    $(s).modal();
}
export default showAlertDialog;
