import i18next from "i18next";

function createFooter(parent)
{
    let s = $(`
<footer class="section footer footer-alt">
    <div class="container">
        <div class="row gutter-vr-30px">
        </div>
    </div>
</footer>
`);
    let gutter = s.find('.gutter-vr-30px');

    gutter.append(`
<div class="col-lg-4 col-sm-12">
    <div class="wgs">
        <div class="wgs-content">
            <div class="wgs-logo">
                <a href="/">
                <img src="/assets/images/logo-black.png" alt="logo">
                </a>
            </div>
            <p>`+i18next.t('common.footer.main.message')+`</p>
        </div>
    </div>
</div>`);

    addGutterColumns('offset-lg-2', 'common.footer.main.services.title', [
        { "href": "/services#what-we-do", "label": "common.footer.main.services.translation"},
        { "href": "/services#what-we-do", "label": "common.footer.main.services.transcription"},
        { "href": "/services#what-we-do", "label": "common.footer.main.services.subtitling"},
        { "href": "/services#what-we-do", "label": "common.footer.main.services.interpreting"},
        { "href": "/services#what-we-do", "label": "common.footer.main.services.postEditing"},
    ]);
    addGutterColumns('', 'common.footer.main.joinUs.title', [
        { "href": "/join-us#selection", "label": "common.footer.main.joinUs.selectionProcess"},
        { "href": "/join-us#join-us", "label": "common.footer.main.joinUs.introduceYourself"},
    ]);
    addGutterColumns('', 'common.footer.main.organization.title', [
        { "href": "/about-us#who-we-are", "label": "common.footer.main.organization.whoWeAre"},
        { "href": "/about-us#our-values", "label": "common.footer.main.organization.ourValues"},
        { "href": "/about-us#why-us", "label": "common.footer.main.organization.whyUs"},
    ]);

    function addGutterColumns(extraClass, title, links)
    {
        let s = '';
        s += '<div class="col-lg-2 col-sm-4 '+extraClass+'"><div class="wgs wgs-links"><div class="wgs-content">';
        s += '<h3 class="wgs-title">';
        s += i18next.t(title);
        s += '</h3>';
        s += '<ul class="wgs-menu">';
        links.forEach((link) => {
            s += '<li><a href="'+i18next.t(link.href)+'">'+i18next.t(link.label)+'</a></li>';
        });
        s += '</ul>';
        s += '</div></div></div>';
        gutter.append(s);
    }
    parent.append(s);
}
export default createFooter;
