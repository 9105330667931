function createCommonBanner(parent, title, message)
{
    parent.append(`
<div class="banner banner-inner banner-s2 banner-s2-inner tc-light">
    <div class="banner-block has-bg-image">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-7 col-sm-9">
                    <div class="banner-content">
                        <div class="line-animate active">
                            <span class="line line-top"></span>
                            <span class="line line-right"></span>
                            <span class="line line-bottom"></span>
                            <span class="line line-left"></span>
                        </div>
                        <p class="sub-heading">`+title+`</p>
                        <h1 class="banner-heading">`+message+`</h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-image overlay-fall bg-image-loaded" style="background-image: url(&quot;/template/images/bg-a.jpg&quot;);">
            <img src="/template/images/bg-a.jpg" alt="banner">
        </div>
    </div>
</div>
    `);
}
export default createCommonBanner;
