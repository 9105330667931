import { AVAILABLE_LANGS } from "./constants";

function isAvailableLanguage(lang)
{
    for(let i = 0; i < AVAILABLE_LANGS.length; i++)
    {
        if(AVAILABLE_LANGS[i] == lang)
        {
            return true;
        }
    }
    return false;
}
export default isAvailableLanguage;
