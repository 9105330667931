import i18next from "i18next";
import AppPage from "../components/AppPage";

class AppPageNotFound implements AppPage
{
    banner(parent)
    {
        parent.append(`
<div class="banner banner-s2 banner-s2-inner tc-bunker">
    <div class="banner-block has-bg-image">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-8 text-center">
                    <div class="error-content">
                        <span class="error-text-large">404</span>
                        <h5>`+i18next.t('common.notFound.title')+`</h5>
                        <p>`+i18next.t('common.notFound.message')+`</p>
                        <a href="/" class="btn">`+i18next.t('common.notFound.button')+`</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-image overlay-fall bg-image-loaded" style="background-image: url(&quot;/template/images/bg-a.jpg&quot;);">
            <img src="/template/images/bg-a.jpg" alt="banner">
        </div>
    </div>
</div>
        `);
    }

    sections(parent)
    {
    }
}
export default AppPageNotFound;
