import i18next from "i18next";

function createHomeSectionJointUs(parent)
{
    parent.append(`
<div class="section section-l tc-grey-alt has-bg-image">
    <div class="container">
        <div class="row justify-content-end">
            <div class="col-md-10 col-lg-5">
                <div class="text-block bg-light block-pad-80">
                    <h5 class="heading-xs dash">`+i18next.t('pages.home.sectionJoinUs.title')+`</h5>
                    <h2>`+i18next.t('pages.home.sectionJoinUs.message')+`</h2>
                    <a href="/join-us" class="btn">`+i18next.t('pages.home.sectionJoinUs.button')+`</a>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-image bg-fixed overlay-fall bg-image-loaded" style="background-image: url(&quot;/template/images/bg-g.jpg&quot;);">
        <img src="/template/images/bg-g.jpg" alt="">
    </div>
</div>`);
}
export default createHomeSectionJointUs;
