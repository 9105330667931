import isAvailableLanguage from "./isAvailableLanguage";

function getLanguage()
{
    let lang = localStorage.getItem('lang');

    if(!isAvailableLanguage(lang))
    {
        lang = navigator.language.split('-')[0];
        if(!isAvailableLanguage(lang))
        {
            lang = 'en';
        }
    }
    return lang;
}
export default getLanguage;
