import i18next from "i18next";

function createContactUsSectionCta(parent)
{
    parent.append(`
<div class="section section-cta bg-primary tc-light section-l cta-s1">
<div class="container">
    <div class="row gutter-vr-30px">
        <div class="col-sm-6 col-lg-4 text-center text-sm-left">
            <div class="cta-box">
                <h2 class="cta-heading">`+i18next.t('pages.contactUs.cta.title')+`</h2>
                <p>`+i18next.t('pages.contactUs.cta.subtitle')+`</p>
                
            </div>
        </div>
        <div class="col-sm-6 offset-lg-3 col-lg-5 text-center text-sm-left">
            <div class="cta-box tc-light">
                <p>`+i18next.t('pages.contactUs.cta.message')+`</p>
                <ul class="social">
                    <li><a href="`+process.env.SOCIAL_LINK_FACEBOOK+`" class="fab fa-facebook-f"></a></li>
                    <li><a href="`+process.env.SOCIAL_LINK_INSTAGRAM+`" class="fab fa-instagram"></a></li>
                    <li><a href="`+process.env.SOCIAL_LINK_TWITTER+`" class="fab fa-twitter"></a></li>
                </ul>
            </div>
        </div>
    </div>
</div>
</div>
    `);
}
export default createContactUsSectionCta;
