
function smoothScrollTo(div)
{
    let offset = div.offset();

    if(offset !== undefined)
    {
        $("html, body").animate
        ({
            scrollTop: offset.top
        });
    }
}
export default smoothScrollTo;
