
function createPreloader(parent)
{
    parent.append(`
    <div class="preloader preloader-light preloader-texas no-split" style="display: none;">
        <span class="spinner spinner-alt load-done">
            <img class="spinner-brand" src="/assets/images/logo-white.png" alt="">
        </span>
    </div>
    `)
}
export default createPreloader;
