import i18next from "i18next";

function createAboutUsSectionWhoWeAre(parent)
{
    parent.append(`
<div class="section section-x tc-grey-alt" id="who-we-are">
<div class="container">
    <div class="row justify-content-between gutter-vr-30px">
        <div class="col-lg-6">
            <div class="image-block">
                <img src="/assets/images/about-us.jpg" alt="">
            </div>
        </div>
        <div class="col-lg-6">
            <div class="text-block mtm-8 text-box-ml-2x">
                <h5 class="heading-xs dash">`+i18next.t('pages.aboutUs.whoWeAre.title')+`</h5>
                <h2>`+i18next.t('pages.aboutUs.whoWeAre.subtitle')+`</h2>
                <p>`+i18next.t('pages.aboutUs.whoWeAre.description')+`</p>
            </div>
        </div>
    </div>
</div>
</div>`);
}
export default createAboutUsSectionWhoWeAre;
