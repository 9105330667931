import i18next from "i18next";

function createJoinUsSectionHowToHire(parent)
{
    let div = $(`
<div class="section section-x tc-grey-alt bg-secondary" id="selection">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-7 text-center">
                <div class="section-head section-md mtm-10">
                    <h2>`+i18next.t('pages.joinUs.howToHire.title')+`</h2>
                    <p>`+i18next.t('pages.joinUs.howToHire.message')+`</p>
                </div>
            </div>
        </div>
        <div class="row justify-content-center gutter-vr-30px">
        </div>
    </div>
</div>`);
    let gutter = div.find('.gutter-vr-30px');

    addBlock(1, 'career-icon-a');
    addBlock(2, 'career-icon-b');
    addBlock(3, 'career-icon-c');
    addBlock(4, 'career-icon-d');

    parent.append(div);

    function addBlock(number, icon)
    {
        gutter.append(`

<div class="col-sm-6 col-lg-3 text-center">
    <div class="career-process bg-light shadow-alt">
        <div class="career-process-icon">
            <h6 class="serial">`+number+`</h6>
            <img src="/template/images/`+icon+`.png" alt="">
        </div>
        <h4>`+i18next.t('pages.joinUs.howToHire.blocks.block'+number)+`</h4>
    </div>
</div>`);
    }
}
export default createJoinUsSectionHowToHire;
