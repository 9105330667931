import AppPage from "../components/AppPage";
import createCommonBanner from "../components/_common/createCommonBanner";
import createContactUsSectionCta from "../components/contact-us/createContactUsSectionCta";
import createContactUsSectionForm from "../components/contact-us/createContactUsSectionForm";
import i18next from "i18next";

class AppPageContact implements AppPage
{
    banner(parent)
    {
        createCommonBanner(parent,
            i18next.t('common.header.banner.contactUs.title'),
            i18next.t('common.header.banner.contactUs.message'));
    }

    sections(parent)
    {
        createContactUsSectionForm(parent);
        createContactUsSectionCta(parent);
    }
}
export default AppPageContact;
