import AppPage from "../components/AppPage";
import createCommonBanner from "../components/_common/createCommonBanner";
import createCommonPreFooter from "../components/_common/createSectionPreFooter";
import createAboutUsSectionWhoWeAre from "../components/about-us/createAboutUsSectionWhoWeAre";
import createAboutUsSectionOurValues from "../components/about-us/createAboutUsSectionOurValues";
import createAboutUsSectionMissionVisionObjetives from "../components/about-us/createAboutUsSectionMissionVisionObjetives";
import i18next from "i18next";

class AppPageAboutUs implements AppPage
{
    banner(parent)
    {
        createCommonBanner(parent,
            i18next.t('common.header.banner.aboutUs.title'),
            i18next.t('common.header.banner.aboutUs.message'));
    }

    sections(parent)
    {
        createAboutUsSectionWhoWeAre(parent);
        createAboutUsSectionOurValues(parent);
        createAboutUsSectionMissionVisionObjetives(parent);
        createCommonPreFooter(parent);
    }
}
export default AppPageAboutUs;
