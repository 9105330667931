import i18next from "i18next";

function createAboutUsSectionOurValues(parent)
{
    let div = $(`
<div class="section section-x bg-secondary is-bg-half tc-grey has-bg-image" id="our-values">

<div class="bg-image bg-half style-right overlay-fall bg-image-loaded" style="background-image: url(&quot;/template/images/post-thumb-md-c.jpg&quot;);">
    <img src="/template/images/post-thumb-md-c.jpg" alt="">
</div>

<div class="container">
    <div class="row">
        <div class="col-md-6">
            <div class="row gutter-vr-30px">
                <div class="col-md-10">
                    <div class="section-head section-head-col m-0">
                        <h5 class="heading-xs dash">`+i18next.t('pages.aboutUs.ourValues.title')+`</h5>
                        <h2>`+i18next.t('pages.aboutUs.ourValues.subtitle')+`</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>`);

    let gutter = div.find('.gutter-vr-30px');

    addValue('teamwork');
    addValue('communication');
    addValue('confidentiality');

    parent.append(div);

    function addValue(value)
    {
        gutter.append(`
    <div class="col-lg-6">
        <div class="text-box res-pr-1rem">
            <h4 class="fw-6">`+i18next.t('pages.aboutUs.ourValues.values.'+value+'.title')+`</h4>
            <p>`+i18next.t('pages.aboutUs.ourValues.values.'+value+'.message')+`</p>
        </div>
    </div>`);
    }
}
export default createAboutUsSectionOurValues;
