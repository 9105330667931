import i18next from "i18next";
import { AVAILABLE_LANGS } from "../../utils/constants";
import getLanguageIcon from "../../utils/getLanguageIcon";

function createHeaderMainNavbar(parent)
{
    let navbar = $(`
<div class="header-navbar">
<nav class="header-menu" id="header-menu">
    <ul class="menu">
    </ul>
</nav>
<div class="header-navbar-overlay">
</div>
</div>`);
    let menu = navbar.find('.menu');

    addLink(menu, '/', i18next.t('common.header.navigation.home'));
    addLink(menu, '/services', i18next.t('common.header.navigation.services'));
    addLink(menu, '/join-us', i18next.t('common.header.navigation.joinUs'));
    addLink(menu, '/about-us', i18next.t('common.header.navigation.aboutUs'));
    addLink(menu, '/contact-us', i18next.t('common.header.navigation.contactUs'));

    menu.append('<li class="menu-btns"></li>');
    addLanguageMenu(menu);

    parent.append(navbar);

    function addLink(parent, href, label)
    {
        let item = $('<li class="menu-item"><a class="menu-link nav-link" href="'+href+'">'+label+'</a></li>');
        parent.append(item);
        item.on('click', () =>
        {
            $('.menu-item').removeClass('active');
            item.addClass('active');
        });
    }

    function addLanguageMenu(parent)
    {
        let s = $(`
<li class="menu-item has-sub menu-item-lang">
    <a class="menu-link nav-link menu-toggle" href="#"><img src="`+getLanguageIcon(i18next.language)+`"/>`+i18next.t('common.header.navigation.language')+`</a>
    <ul class="menu-sub menu-drop">
    </ul>
</li>`);
        s.find('a').on('click', (e) =>
        {
            e.preventDefault();
            e.stopPropagation();
        });

        let list = s.find('.menu-drop');

        AVAILABLE_LANGS.forEach((lang) =>
        {
            addLanguageMenuItem(list, lang);
        });

        parent.append(s);
    }

    function addLanguageMenuItem(parent, lang)
    {
        let item = $('<li class="menu-item"><a href="#"><img src="'+getLanguageIcon(lang)+'"/>'+i18next.t('common.lang.' + lang)+'</a></li>');
        
        item.on('click', (e) =>
        {
            e.preventDefault();
            e.stopPropagation();
            i18next.changeLanguage(lang);
            localStorage.setItem('lang', lang);
            window.location.reload();
        });
        parent.append(item);
    }
}
export default createHeaderMainNavbar;
