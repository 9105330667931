import i18next from "i18next";
import showAlertDialog from "../../dialogs/showAlertDialog";

function createContactUsSectionForm(parent)
{
    let div = $(`
<div class="section section-x bg-light contact-bg-map has-bg-image" id="contact-form">
<div class="container container-lg-custom">
    <div class="row gutter-vr-40px justify-content-between align-items-center">
        <div class="col-lg-7 col-md-6 order-last pl-xl-5">
            <div class="row gutter-vr-30px">
                <div class="col-sm-6 col-md-12 col-lg-6">
                    <div class="contact-text contact-text-s3 bg-light box-pad box-pad-md">
                        <div class="text-box">
                            <h4><i class="contact-icon ti-mobile"/>`+i18next.t('common.label.phone')+`</h4>
                            <p>`+process.env.APP_PHONE+`</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-12 col-lg-6 order-lg-last order-first">
                    <div class="contact-text contact-text-s3 bg-light box-pad mt-top box-pad-md">
                        <div class="text-box">
                            <h4><i class="contact-icon ti-email"/>`+i18next.t('common.label.email')+`</h4>
                            <p>`+process.env.APP_EMAIL+`</p>
                        </div>
                    </div>`+
                    /*<div class="contact-text contact-text-s3 bg-light box-pad mt-top box-pad-md contact-us-text-custom">
                        <div class="text-box">
                            <h4><i class="contact-icon ti-map-alt"/>`+i18next.t('common.label.address')+`</h4>
                            <p>`+process.env.APP_ADDRESS+`</p>
                        </div>
                    </div>*/
                `</div>
            </div>
        </div>
        <div class="col-lg-5 col-md-6">
            <div class="section-head section-head-s2 section-sm">
                <h2>`+i18next.t('pages.contactUs.form.title')+`</h2>
            </div>
            <form class="genox-form genox-form-s2">
                <div class="form-results"></div>
                <div class="row">
                    <div class="form-field col-12">
                        <input name="name" type="text" placeholder="`+i18next.t('pages.contactUs.form.inputName')+`" class="input bdr-b bdr-b-light required pt-0" aria-required="true">
                    </div>
                    <div class="form-field col-12">
                        <input name="surname" type="text" placeholder="`+i18next.t('pages.contactUs.form.inputSurname')+`" class="input bdr-b bdr-b-light required pt-0" aria-required="true">
                    </div>
                    <div class="form-field col-12">
                        <input name="email" type="email" placeholder="`+i18next.t('pages.contactUs.form.inputEmail')+`" class="input bdr-b bdr-b-light required" aria-required="true">
                    </div>
                    <div class="form-field col-12">
                        <textarea name="message" placeholder="`+i18next.t('pages.contactUs.form.inputMessage')+`" class="input input-msg bdr-b bdr-b-light required" aria-required="true"></textarea>
                        <input type="text" class="d-none" name="form-anti-honeypot" value="">
                    </div>
                    <div class="form-btn col-12">
                        <button type="submit" class="btn btn-lg-s2 round-sm btn-capitalize">`+i18next.t('pages.contactUs.form.button')+`</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="bg-image overlay-fall bg-image-loaded" style="background-image: url(&quot;/template/images/contact-bg.png&quot;);">
    <img src="/template/images/contact-bg.png" alt="banner">
</div>
</div>
`);

    let form = div.find('form');

    form.validate({
        rules: {
            name:       { required: true, },
            surname:    { required: true, },
            email:      { required: true, email: true, },
            message:    { required: true, },
        },
        messages: {
            name: {
                required: i18next.t('validate.name.required'),
            },
            surname: {
                required: i18next.t('validate.surname.required'),
            },
            email: {
                required: i18next.t('validate.email.required'),
                email: i18next.t('validate.email.email'),
            },
            message: {
                required: i18next.t('validate.message.required'),
            },
        }
    });

    form.on('submit', (e) =>
    {
        e.preventDefault();
        e.stopPropagation();

        if(form.valid())
        {
            let frm: any = form[0];
            let formData = new FormData(frm);

            $.ajax({
                url: process.env.CONTACT_US_URL,
                type: 'post',
                data: formData,
                cache: false,
                contentType: false,
                processData: false,
            })
            .done(function(data, status, jqxhr)
            {
                showAlertDialog({
                    type: 'success',
                    title: i18next.t("common.header.navigation.contactUs"),
                    message: i18next.t('dialog.submitResult.doneMessage')
                });
                form.find('input[name="name"]').val('');
                form.find('input[name="surname"]').val('');
                form.find('input[name="email"]').val('');
                form.find('textarea[name="message"]').val('');
            })
            .fail(function(jqxhr, status, error)
            {
                showAlertDialog({
                    type: 'danger',
                    title: i18next.t("common.header.navigation.contactUs"),
                    message: i18next.t('dialog.submitResult.failedMessage')
                });
            })
            ;
        }
    });
    parent.append(div);
}
export default createContactUsSectionForm;
