import Router from "./router/Router";
import RouteArguments from "./router/RouteArguments";
import RouteHome from "./routes/RouteHome";
import RouteServices from "./routes/RouteServices";
import RouteJoinUs from "./routes/RouteJoinUs";
import RouteAboutUs from "./routes/RouteAboutUs";
import RouteContactUs from "./routes/RouteContactUs";
import RouteNotFound from "./routes/RouteNotFount";

import '../scss/styles.scss';

import i18next from 'i18next';
import i18nextBackend from 'i18next-xhr-backend';
import getLanguage from "./utils/getLanguage";
import hack from "./utils/hack";
import { AVAILABLE_LANGS } from "./utils/constants";

$(document).ready(function()
{
    new Promise((done, reject) =>
    {
        i18next
        .use(i18nextBackend)
        .init({
            lng: getLanguage(),
            debug: true,
            fallbackNS: false,
            fallbackLng: false,
            nsSeparator: false,
            //keySeparator: '.', // By default

            ns: [
                'translation'
            ],
            backend: {
                loadPath: '/assets/translations/{{lng}}/{{ns}}.json'
            },
            whitelist: AVAILABLE_LANGS,
        },
        (error, t) =>
        {
            if(!error)
                done();
            else
                reject('i18next init promise reject');
        });
    })
    .then(() =>
    {
        $.validator.addMethod("phone", function(value, element, params)
        {
            return /^(\+?([0-9]{2})\s?([0-9]{1})\s?([0-9]{3})\s?([0-9]{3})\s?([0-9]{4})){0,1}$/.test(value);
        });
        Router.init({
            routes: [
                { path: '/', route: RouteHome, },
                { path: '/services', route: RouteServices, },
                { path: '/join-us', route: RouteJoinUs, },
                { path: '/about-us', route: RouteAboutUs, },
                { path: '/contact-us', route: RouteContactUs, },
            ],
    
            default: RouteNotFound,
    
            postRoute: function(args: RouteArguments)
            {
            }
        });
        hack();
    })
    .catch((message) =>
    {
        $(document.body).html(message);
    });
});
