import i18next from "i18next";

function createCommonPreFooter(parent)
{
    parent.append(`
<div class="section section-cta tc-light has-bg-image">
    <div class="container">
        <div class="row gutter-vr-30px align-items-center justify-content-between">
            <div class="col-lg-8 text-center text-lg-left">
                <div class="cta-text-s2">
                    <h2><span>`+i18next.t('common.footer.preFooter.title')+`</span> <strong>`+i18next.t('common.footer.preFooter.message')+`</strong></h2>
                </div>
            </div>
            <div class="col-lg-4 text-lg-right text-center">
                <div class="cta-btn">
                    <a href="/contact-us#contact-form" class="btn">`+i18next.t('common.footer.preFooter.button')+`</a>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-image bg-fixed overlay-fall bg-image-loaded" style="background-image: url(&quot;/template/images/bg-l.jpg&quot;);">
        <img src="/template/images/bg-l.jpg" alt="">
    </div>
</div>`);
}
export default createCommonPreFooter;
