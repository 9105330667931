import i18next from "i18next";

function createServicesSectionX(parent)
{
    let div = $(`
<div class="section section-l bg-secondary">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-7 text-center">
                <div class="section-head section-md tc-dark">
                    <h5 class="heading-xs dash dash-both">`+i18next.t('pages.services.sectionHowWeDiffer.title')+`</h5>
                </div>
            </div>
        </div>
        <div class="row justify-content-center gutter-vr-30px">
        </div>
    </div>
</div>
`);
    let gutter = div.find('.gutter-vr-30px');

    addStep(1, 5);
    addStep(2, 1);
    addStep(3, 2);

    parent.append(div);

    function addStep(number, itemsCount)
    {
        gutter.append(`
<div class="col-lg-4 col-md-6 text-center">
    <div class="feature feature-s2 feature-s2-alt-inner bg-light">
        <div class="icon-box">
            <strong class="icon">`+number+`</strong>
            <div class="icon-box-content">
                <h6 class="tc-primary">`+i18next.t('pages.services.sectionHowWeDiffer.block'+number+'.title')+`</h6>
            </div>
        </div>
        <div class="feature-content">
            <div class="text-box">
            <ul class="list">
            `+
            ((() =>
            {
                let s = '';
                for(let i = 1; i <= itemsCount; i++)
                {
                    s += '<li>'+i18next.t('pages.services.sectionHowWeDiffer.block'+number+'.item'+i)+'</li>';
                }
                return s;
            })())
            +`
            </ul>
        </div>
        </div>
    </div>
</div>`);
    }
}
export default createServicesSectionX;
