import i18next from "i18next";

function createHomeSectionServices(parent)
{
    let div = $(`
<div class="section section-x tc-grey-alt" id="what-we-do">
    <div class="container">
        <div class="row justify-content-between gutter-vr-30px">
            <div class="col-lg-4 col-xl-3 col-md-8">
                <div class="section-head section-head-col">
                    <h5 class="heading-xs dash">`+i18next.t('pages.home.sectionServices.title')+`</h5>
                    <h2>`+i18next.t('pages.home.sectionServices.subtitle')+`</h2>
                    <p class="lead">`+i18next.t('pages.home.sectionServices.description')+`</p>
                    <a href="/services" class="btn">`+i18next.t('pages.home.sectionServices.button')+`</a>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="row gutter-vr-30px gutter-vr-30px-res">
                </div>
            </div>
        </div>
    </div>
</div>`);
    let gutter = div.find('.gutter-vr-30px-res');

    addFeature('ti-comment-alt', 'translation');
    addFeature('ti-write', 'transcription');
    addFeature('ti-smallcap', 'subtitling');
    addFeature('ti-headphone-alt', 'interpreting');
    addFeature('ti-desktop', 'postEditing');

    parent.append(div);

    function addFeature(icon, service)
    {
        gutter.append(`
<div class="col-sm-6">
    <div class="feature">
        <div class="feature-icon">
            <em class="icon `+icon+`"></em>
        </div>
        <div class="feature-content">
            <h3>`+i18next.t('pages.home.sectionServices.services.'+service+'.title')+`</h3>
        </div>
    </div>
</div>`);
    }
}
export default createHomeSectionServices;
