import { hasData } from "jquery";
import { off } from "process";
import RouteArguments from "../router/RouteArguments";
import smoothScrollTo from "../utils/smoothScrollTo";
import AppPage from "./AppPage";
import createCopyright from "./_common/createCopyright";
import createFooter from "./_common/createFooter";
import createHeaderMain from "./_common/createHeaderMain";
import createPreloader from "./_common/createPreloader";

interface AppData
{
    header?,
    sections?,
}

let data: AppData = null;

function app(args: RouteArguments, page: AppPage)
{
    window.scrollTo(0,0); 
    if(data == null)
    {
        let body = $(document.body);
        data = { };

        data.header = $('<header class="is-transparent is-sticky is-shrink" id="header">');
        data.sections = $('<div></div>');
        
        body.append(data.header);
        body.append(data.sections);
        createHeaderMain(data.header);
        createFooter(body);
        createCopyright(body);
        createPreloader(body);
    }
    else
    {
        data.header.find('.banner').remove();
        data.sections.html('');
    }
    page.banner(data.header);
    page.sections(data.sections);

    setTimeout(() =>
    {
        smoothScrollTo($(window.location.hash));

        $('.header-navbar a.menu-link').parent().removeClass('active');
        $('.header-navbar a.menu-link[href="'+window.location.pathname+'"]').parent().addClass("active");
    }, 200);
}
export default app;
