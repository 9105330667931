import createHeaderMainNavbar from "./createHeaderMainNavbar";

function createHeaderMain(parent)
{
    let s = $('<div class="header-main"/>');
    let container = $('<div class="header-container container"/>');
    let wrap = $('<div class="header-wrap"></div>');

    s.append(container)
    container.append(wrap);

    // Logo
    wrap.append(`
<div class="header-logo logo">
    <a href="/" class="logo-link">
    <img class="logo-dark" src="/assets/images/logo-red.png" alt="logo">
    <img class="logo-light" src="/assets/images/logo-white.png" alt="logo">
    </a>
</div>`);

    // Menu Toogle
    wrap.append(`
<div class="header-nav-toggle">
    <a href="#" class="navbar-toggle" data-menu-toggle="header-menu">
        <div class="toggle-line">
            <span></span>
        </div>
    </a>
</div>`);
    // Menu
    createHeaderMainNavbar(wrap);

    parent.append(s);
}
export default createHeaderMain;
