import AppPage from "../components/AppPage";
import createCommonBanner from "../components/_common/createCommonBanner";
import createCommonPreFooter from "../components/_common/createSectionPreFooter";
import createServicesSectionSteps from "../components/services/createServicesSectionSteps";
import createServicesSectionHowWeDiffer from "../components/services/createServicesSectionHowWeDiffer";
import createServicesSectionWhatWeDo from "../components/services/createServicesSectionOurServices";
import i18next from "i18next";
import createServicesSectionX from "../components/services/createServicesSectionX";

class AppPageServices implements AppPage
{
    banner(parent)
    {
        createCommonBanner(parent,
            i18next.t('common.header.banner.services.title'),
            i18next.t('common.header.banner.services.message'));
    }

    sections(parent)
    {
        createServicesSectionWhatWeDo(parent);
        //createServicesSectionSteps(parent);
        //createServicesSectionHowWeDiffer(parent);
        createServicesSectionX(parent);
        createCommonPreFooter(parent);
    }
}
export default AppPageServices;
