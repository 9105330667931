import AppPage from "../components/AppPage";
import createCommonPreFooter from "../components/_common/createSectionPreFooter";
import createHomeSectionServices from "../components/home/createHomeSectionServices";
import createHomeSectionJointUs from "../components/home/createHomeSectionJointUs";
import i18next from "i18next";
import smoothScrollTo from "../utils/smoothScrollTo";

class AppPageHome implements AppPage
{
    banner(parent)
    {
        parent.append(`
<div class="banner banner-s2">
    <div class="banner-block has-bg-image">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-sm-2 order-last order-sm-first">
                    <ul class="social social-alt">
                        <li><a href="`+process.env.SOCIAL_LINK_FACEBOOK+`" class="fab fa-facebook-f"></a></li>
                        <li><a href="`+process.env.SOCIAL_LINK_INSTAGRAM+`" class="fab fa-instagram"></a></li>
                        <li><a href="`+process.env.SOCIAL_LINK_TWITTER+`" class="fab fa-twitter"></a></li>
                    </ul>
                </div>
                <div class="col-10 col-sm-9">
                    <div class="banner-content">
                        <div class="line-animate active">
                            <span class="line line-top"></span>
                            <span class="line line-right"></span>
                            <span class="line line-bottom"></span>
                            <span class="line line-left"></span>
                        </div>
                        <p class="sub-heading">`+i18next.t('common.header.banner.home.title')+`</p>
                        <h1 class="banner-heading size-sm">`+i18next.t('common.header.banner.home.message')+`</h1>
                        <div class="banner-btn">
                            <h6>`+i18next.t('common.header.banner.home.button')+`</h6>
                            <a href="#what-we-do" class="btn-scroll">
                                <em class="ti-angle-down"></em>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-image overlay-fall bg-image-loaded" style="background-image: url(&quot;/template/images/banner-home.jpg&quot;);">
            <img src="/template/images/banner-home.jpg" alt="banner">
        </div>
    </div>
</div>
        `);
        parent.find('.btn-scroll').on('click', (e) =>
        {
            e.preventDefault();
            e.stopPropagation();

            smoothScrollTo($('#what-we-do'));
        });
    }

    sections(parent)
    {
        createHomeSectionServices(parent);
        createHomeSectionJointUs(parent);
        //createHomeSectionReviews(parent);
        createCommonPreFooter(parent);
    }
}
export default AppPageHome;
