import i18next from "i18next";
import showAlertDialog from "../../dialogs/showAlertDialog";

function createJoinUsSectionForm(parent)
{
    let div = $(`
<div class="section section-x" id="join-us">
    <div class="container">
        <div class="row gutter-vr-30px">
            <div class="col-lg-4">
                <div class="text-block">
                    <div class="section-head">
                        <h5 class="heading-xs dash fw-4">`+i18next.t('pages.joinUs.form.title')+`</h5>
                        <h2>`+i18next.t('pages.joinUs.form.description')+`</h2>
                    </div>
                </div>
            </div>
            <div class="col-lg-8">
                <form class="genox-form" method="POST" novalidate="novalidate">
                    <div class="form-results"></div>
                    <input name="action" type="hidden" value="join-us">
                    <div class="row">
                        <div class="form-field col-md-6">
                            <input name="name" type="text" placeholder="`+i18next.t('pages.joinUs.form.inputName')+`" class="input bg-secondary required" aria-required="true">
                        </div>
                        <div class="form-field col-md-6">
                            <input name="surname" type="text" placeholder="`+i18next.t('pages.joinUs.form.inputSurname')+`" class="input bg-secondary required" aria-required="true">
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-field col-md-6">
                            <input name="email" type="email" placeholder="`+i18next.t('pages.joinUs.form.inputEmail')+`" class="input bg-secondary required" aria-required="true">
                        </div>
                        <div class="form-field col-md-6">
                            <div class="curriculum_vitae">
                                <input name="curriculum_vitae_placeholder" type="text" placeholder="`+i18next.t('pages.joinUs.form.inputCurriculumVitae')+`" class="input bg-secondary">
                                <input name="curriculum_vitae" type="file" class="input bg-secondary required"  aria-required="true" accept="application/pdf">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-field col-md-6">
                            <input type="text" name="phone" class="input bg-secondary" placeholder="`+i18next.t('pages.joinUs.form.inputPhone')+`">
                        </div>
                        <div class="form-field col-md-6">
                            <input name="linkedin" type="url" placeholder="`+i18next.t('pages.joinUs.form.inputLinkedin')+`" class="input bg-secondary">
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-field col-md-12">
                            <textarea name="message" placeholder="`+i18next.t('pages.joinUs.form.inputMessage')+`" class="input input-msg bg-secondary required" aria-required="true"></textarea>
                            <input type="text" class="d-none" name="form-anti-honeypot" value="">
                        </div>
                        <div class="form-field col-md-12">
                            <button type="submit" class="btn btn-md">`+i18next.t('pages.joinUs.form.button')+`</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>`);

    let form = div.find('form');

    form.validate({
        rules: {
            name:               { required: true, },
            surname:            { required: true, },
            email:              { required: true, email: true, },
            curriculum_vitae:   { required: true, },
            phone: {
                phone: 'phone',
            },
            linkedin:           { url: true, },
            message:            { required: true, },
        },
        messages: {
            name: {
                required: i18next.t('validate.name.required'),
            },
            surname: {
                required: i18next.t('validate.surname.required'),
            },
            email: {
                required: i18next.t('validate.email.required'),
                email: i18next.t('validate.email.email'),
            },
            curriculum_vitae: {
                required: i18next.t('validate.curriculum_vitae.required'),
            },
            phone: {
                phone: i18next.t('validate.phone.phone') + '<br>(+XX X XXX XXX XXXX)',
            },
            linkedin: {
                url: i18next.t('validate.linkedin.url'),
            },
            message: {
                required: i18next.t('validate.message.required'),
            },
        }
    });
    let cvFileInput: any|HTMLInputElement = form.find('input[name="curriculum_vitae"]')[0];
    let cvPlaceholderInput: any|HTMLInputElement = form.find('input[name="curriculum_vitae_placeholder"]')[0];

    $(cvFileInput).on('change', (e) =>
    {
        let placeholder;
        if(cvFileInput.files.length > 0)
            placeholder = cvFileInput.files[0].name;
        else
            placeholder = i18next.t('pages.joinUs.form.inputCurriculumVitae');
        $(cvPlaceholderInput).attr('placeholder', placeholder);
    });

    form.on('submit', (e) =>
    {
        e.preventDefault();
        e.stopPropagation();

        if(form.valid())
        {
            let frm: any = form[0];
            let formData = new FormData(frm);

            $.ajax({
                url: process.env.JOIN_US_URL,
                type: 'post',
                data: formData,
                cache: false,
                contentType: false,
                processData: false,
            })
            .done(function(data, status, jqxhr)
            {
                form.find('input[name="name"]').val('');
                form.find('input[name="surname"]').val('');
                form.find('input[name="email"]').val('');
                form.find('input[name="phone"]').val('');
                form.find('input[name="linkedin"]').val('');
                form.find('input[name="curriculum_vitae"]').val('');
                form.find('textarea[name="message"]').val('');
                let placeholder = i18next.t('pages.joinUs.form.inputCurriculumVitae');
                $(cvPlaceholderInput).attr('placeholder', placeholder);
                showAlertDialog({
                    type: 'success',
                    title: i18next.t("common.header.navigation.joinUs"),
                    message: i18next.t('dialog.submitResult.doneMessage')
                });
            })
            .fail(function(jqxhr, status, error)
            {
                showAlertDialog({
                    type: 'danger',
                    title: i18next.t("common.header.navigation.joinUs"),
                    message: i18next.t('dialog.submitResult.failedMessage')
                });
            })
            ;
        }
    });
    parent.append(div);
}
export default createJoinUsSectionForm;
