import i18next from "i18next";

function createCopyright(parent)
{
   parent.append(`
<div class="copyright">
   <div class="container bdr-copyright">
       <div class="row align-items-center justify-content-between">
           <div class="col-md-8">
               <div class="copyright-content">
                   <p>© `+(new Date().getFullYear())+`.`+i18next.t('common.footer.copyright')+`</p>
               </div>
           </div>
           <div class="col-md-4 text-md-right">
               <div class="copyright-content">
                   <ul class="social social-style-icon">
                       <li><a href="`+process.env.SOCIAL_LINK_FACEBOOK+`" class="fab fa-facebook-f"></a></li>
                       <li><a href="`+process.env.SOCIAL_LINK_INSTAGRAM+`" class="fab fa-instagram"></a></li>
                       <li><a href="`+process.env.SOCIAL_LINK_TWITTER+`" class="fab fa-twitter"></a></li>
                   </ul>
               </div>
           </div>
       </div>
   </div>
</div>
   `);
}
export default createCopyright;
