import i18next from "i18next";

function createAboutUsSectionMissionVisionObjetives(parent)
{
    let div = $(`
<div class="section section-l bg-dark tc-grey-alt fw-3 section-mission-vision-objectives" id="why-us">
<div class="container">
    <div class="row justify-content-center gutter-vr-30px">
    </div>
</div>
</div>
`);
    let gutter = div.find('.gutter-vr-30px');

    addBlock('objectives');
    addBlock('mission');
    addBlock('vision');
    parent.append(div);

    function addBlock(name)
    {
        gutter.append(`
<div class="col-md-4 text-center">
    <div class="text-box bg-light h-full service-box">
        <h4>`+i18next.t('pages.aboutUs.ourMissionVisionObjectives.'+name+'.title')+`</h4>
        <p>`+i18next.t('pages.aboutUs.ourMissionVisionObjectives.'+name+'.message')+`</p>
    </div>
</div>`);
    }
}
export default createAboutUsSectionMissionVisionObjetives;
