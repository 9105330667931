import i18next from "i18next";

function createServicesSectionWhatWeDo(parent)
{
    let div = $(`
<div class="section section-l bg-light tc-grey-alt" id="what-we-do">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-md-5">
                <div class="section-head section-md res-m-btm">
                    <h5 class="heading-xs dash">`+i18next.t('pages.services.sectionServices.title')+`</h5>
                    <h2>`+i18next.t('pages.services.sectionServices.subtitle')+`</h2>
                </div>
            </div>
            <div class="col-md-6">
                <div class="section-head section-md">
                    <div class="text-box">
                        <p class="lead">`+i18next.t('pages.services.sectionServices.description')+`</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center gutter-vr-30px">
        </div>
    </div>
</div>
    `);
    let gutter = div.find('.gutter-vr-30px');
 
    createBlock('ti-comment-alt', 'translation');
    createBlock('ti-write', 'transcription');
    createBlock('ti-smallcap', 'subtitling');
    createBlock('ti-headphone-alt', 'interpreting');
    createBlock('ti-desktop', 'postEditing');

    parent.append(div);

    function createBlock(icon, service)
    {
        gutter.append(`
<div class="col-sm-6 col-xl-3">
    <div class="feature feature-s2 feature-s2-inner bdr">
        <div class="feature-icon">
            <em class="`+icon+`"></em>
        </div>
        <div class="feature-content-s3">
            <h3>`+i18next.t('pages.services.sectionServices.services.'+service+'.title')+`</h3>
            <p>`+i18next.t('pages.services.sectionServices.services.'+service+'.message')+`</p>
        </div>
    </div>
</div>`);
    }
}
export default createServicesSectionWhatWeDo;
